// Ensure TNCMS namespace parent is declared
window.TNCMS = window.TNCMS || {};

/**
 * BLOX Subscription JavaScript Interface
 * 
 * Provides utility methods for determining whether a page requires access to
 * view, the viewer has sufficient access, and whether any access permitted is
 * as a direct result of the user being active on one or more service IDs
 * matching those restricting the page being viewed. Interfaces with other
 * BLOX components to provide impressions for use in AAM tracking.
 */
window.TNCMS.Subscription = (function () {

	"use strict";

	// {{{ Private Data

	var m_bAccessByAny = null;
	var m_bAccessByIp = null;
	var m_bAccessBySvc = null;
	var m_bAccessBySvcOwn = null;
	var m_bSvcAny = null;
	var m_bSvcAdmin = null;
	var m_bSvcOwn = null;
	var m_bRestricted = null;
	var m_bUnrestricted = null;
	var m_bTrack = null;
	var m_aIdsUrl = null;
	var m_aIds = null;
	var m_aIdsMatch = null;
	var m_oTracker = null;
	var m_oMeta = null;

	var m_sErrorOnAccessByIp = 'Failed to initialize';

	var m_aFnsOnReady = [];
	var m_oReq = null;

	var m_sComma = ',';
	var m_sCommaEnc = '%2C';

	// The API to export to callbacks on ready
	var m_oExport = {
		// {{{ boolean isRestricted( void )

		/**
		 * Determine whether the current resource is restricted.
		 * 
		 * @return bool True iff resource is restricted by any service IDs.
		 */
		isRestricted: function _isRestricted() {
			return m_bRestricted;
		},

		// }}}
		// {{{ string accessByIpError( void )

		/**
		 * Get any error having occurred while checking access by IP.
		 *
		 * @return string An error message or null.
		 */
		accessByIpError: function _accessByIpError() {
			return m_sErrorOnAccessByIp;
		},

		// }}}
		// {{{ bool accessByIp( void )

		/**
		 * Determine whether the client IP provides access.
		 *
		 * @return bool True iff client IP provides access.
		 */
		accessByIp: function _accessByIp() {
			return !!m_bAccessByIp;
		},

		// }}}
		// {{{ bool allowAccess(void)

		/**
		 * Determine whether access is permitted by any means, but only after
		 * onReady calls your callback.
		 *
		 * @return bool True iff the resource is unrestricted or the
		 *              user has appropriate access.
		 * @requires TNCMS.User.isLoggedIn
		 */
		allowAccess: function _allowAccess() {
			return !!m_bAccessByAny;
		},

		// }}}
		// {{{ bool userHasService(void)

		/**
		 * Determine whether user has access by matching a service ID, but only
		 * after onReady calls your callback.
		 *
		 * @return bool True iff a user service ID match grants access.
		 * @requires TNCMS.User.isLoggedIn
		 */
		userHasService: function _accessByOwnService() {
			return m_bAccessBySvcOwn;
		}

		// }}}
	};

	// }}}
	// {{{ Private Methods

	// {{{ void debugMsg( string sMessage )
	
	function debugMsg( sMessage )
	{
		if (console && console.debug) {
			console.debug(sMessage);
		}
	}
	
	// }}}
	// {{{ void _forEach( Array aToIter, Function oCb, Object oScope )

	function _forEach(aToIter, oCb, oScope) {
		var i, c;
		for (i = 0, c = aToIter.length; i < c; i++) {
			oCb.call(oScope, i, aToIter[i]);
		}
	}

	// }}}
	// {{{ string _getCookieValueByName( string sName )

	/**
	 * Retrieve the value of a cookie.
	 * 
	 * @param string sName
	 * 	The name of the cookie to retrieve.
	 * 
	 * @return string
	 * 	The value of the cookie found or null.
	 */
	function _getCookieValueByName(sName)
	{
		var sNameEQ = sName + "=";
		var aCookie = document.cookie.split(';');
		for (var i = 0, c = aCookie.length; i < c; i++) {
			var sChunk = aCookie[i];
			while (sChunk.charAt(0) == ' ') {
				sChunk = sChunk.substring(1, sChunk.length);
			}

			if (sChunk.indexOf(sNameEQ) == 0) {
				return sChunk.substring(sNameEQ.length, sChunk.length);
			}
		}
		return null;
	}

	// }}}
	// {{{ bool _isArray( mixed x )

	function _isArray(x) {
		return (x && !(x.propertyIsEnumerable('length')) &&
			typeof x === 'object' && typeof x.length === 'number'
		);
	}

	// }}}
	// {{{ array _sanitizeStrArr( mixed x )

	/**
	 * Normalize the passed string array to an array of strings or null.
	 * 
	 * @return array
	 * 	Returns an array for valid non-empty string array or null.
	 */
	function _sanitizeStrArr(x) {
		if (!_isArray(x) && !!x && typeof x === 'string' &&
			x.indexOf(m_sCommaEnc) !== -1
		) {
			x = x.split(m_sCommaEnc).join(m_sComma);
		}
		if (!_isArray(x) && !!x && typeof x === 'string') {
			x = x.split(m_sComma);
		}
		if (_isArray(x)) {
			return x;
		}
	}

	// }}}
	// {{{ bool _isRestricted( void )

	/**
	 * Determine whether the current resource is restricted.
	 * 
	 * @return bool
	 * 	True iff service IDs array restricting the resource isn't empty.
	 */
	function _isRestricted()
	{
		if (m_bRestricted === null) {
			m_bRestricted = !!m_aIdsUrl;
		}
		return m_bRestricted;
	}

	// }}}
	// {{{ bool _accessByIp( void )

	/**
	 * Determine whether the client IP provides access.
	 *
	 * @return bool True iff restricted and client IP provides access.
	 */
	function _accessByIp()
	{
		return !!m_bAccessByIp;
	}

	// }}}
	// {{{ bool _hasServiceAny( void )

	/**
	 * Determine whether a user has any service IDs.
	 * 
	 * @return bool
	 * 	True iff has no IP access, user is logged in, and has any service IDs.
	 * 
	 * @requires TNCMS.User.isLoggedIn
	 */
	function _hasServiceAny()
	{
		if (m_bSvcAny === null) {
			if (TNCMS.User.isLoggedIn()) {
				m_aIds = _sanitizeStrArr(_getCookieValueByName(
					'tncms-services'
				));
			}
			m_bSvcAny = !!m_aIds;

			if (m_bSvcAny === false) {
				// Booleans dependant on this can't be true if this isn't
				m_bSvcAdmin = false;
				m_bSvcOwn = false;
				// Arrays dependant on this are empty if this is false
				m_aIdsMatch = [];
				m_aIds = [];
			}
		}
		return m_bSvcAny;
	}

	// }}}
	// {{{ bool _accessByService( void )

	/**
	 * Determine whether a user has access due specifically to service ID.
	 * 
	 * @return bool
	 * 	True iff the user is logged in and has ID 0 or any matching service ID.
	 * 
	 * @requires TNCMS.User.isLoggedIn
	 */
	function _accessByService()
	{
		if (m_bAccessBySvc === null) {
			if (_hasServiceAny() === true) {
				m_aIdsMatch = [];
				var n0 = 0;
				for (var i = 0, c = m_aIds.length; i < c; i++) {
					var x = m_aIds[i];
					if (x == n0) {
						// Could be set elsewhere, particularly _hasServiceAny
						if (m_bSvcAdmin === null) {
							m_bSvcAdmin = true;
						}
						continue;
					}

					for (var j = 0, d = m_aIdsUrl.length; j < d; j++) {
						if (x == m_aIdsUrl[j]) {
							m_aIdsMatch = m_aIdsMatch.concat(x);
						}
					}
				}

				if (m_bSvcAdmin === null) {
					m_bSvcAdmin = false; // default if not set here
				}
			}

			// Being identified as an admin overrides own service access
			if (m_bSvcAdmin) {
				m_bSvcOwn = false;
				m_aIdsMatch.length = 0;
			} else if (m_bSvcOwn === null) {
				m_bSvcOwn = _isArray(m_aIdsMatch) && m_aIdsMatch.length > 0;
			}

			m_bAccessBySvc = m_bSvcAdmin || m_bSvcOwn;
		}
		return m_bAccessBySvc;
	}

	// }}}
	// {{{ bool _hasServiceOwn( void )

	/**
	 * Determine whether a user specifically has access via their own services.
	 * 
	 * @return bool
	 * 	True iff no IP access, is logged in, no admin access, and IDs match.
	 * 
	 * @requires TNCMS.User.isLoggedIn
	 */
	function _hasServiceOwn()
	{
		if (m_bSvcOwn === null) {
			// Unconditionally computes both admin and own service status
			_accessByService();
		}
		return m_bSvcOwn;
	}

	// }}}
	// {{{ bool _hasServiceAdmin( void )

	/**
	 * Determine whether a user specifically has admin access.
	 * 
	 * @return bool
	 * 	True iff no IP access, is logged in, and has admin access (ID 0).
	 * 
	 * @requires TNCMS.User.isLoggedIn
	 */
	function _hasServiceAdmin()
	{
		if (m_bSvcAdmin === null) {
			// Unconditionally computes both admin and own service status
			_accessByService();
		}
		return m_bSvcAdmin;
	}

	// }}}
	// {{{ array _matchingServiceIds( void )

	/**
	 * Return an array of service IDs granting the user their own access.
	 * 
	 * @return array
	 * 	Service IDs the user is active on which match any restricting the URL.
	 * 
	 * @requires TNCMS.User.isLoggedIn
	 */
	function _matchingServiceIds()
	{
		return (_hasServiceOwn() ? m_aIdsMatch.slice(0, 1) : []);
	}

	// }}}
	// {{{ bool _accessByAny( void )

	/**
	 * Determine whether a user has access by any means.
	 * 
	 * @return bool
	 * 	True iff the resource is unrestricted or the user has appropriate access.
	 * 
	 * @requires TNCMS.User.isLoggedIn
	 */
	function _accessByAny()
	{
		// If not yet computed: true iff unrestricted or access by IP/service
		if (m_bAccessByAny === null) {
			m_bAccessByAny = (
				(_isRestricted() || false) ? (
				_accessByIp() || _accessByService() || false) : true
			);
		}
		return m_bAccessByAny;
	}

	// }}}
	// {{{ bool _accessByOwnService( void )

	/**
	 * Determine whether user has access expressly by matching service ID.
	 * 
	 * @return bool
	 * 	True iff resource restricted and access by user service ID match.
	 * 
	 * @requires TNCMS.User.isLoggedIn
	 */
	function _accessByOwnService()
	{
		// If not yet computed: true iff restricted and access by own service
		if (m_bAccessBySvcOwn === null) {
			m_bAccessBySvcOwn = (_isRestricted() || false ?
				_hasServiceOwn() : false
			);
		}
		return m_bAccessBySvcOwn;
	}

	// }}}
	// {{{ bool _trackAam( void )

	/**
	 * Triggers AAM tracking beacon for users viewing pages to be tracked.
	 * 
	 * @return bool
	 * 	True iff page and user qualify for AAM tracking.
	 * 
	 * @requires TNCMS.User.isLoggedIn
	 * @requires TNCMS.Tracking.addEvent
	 * @requires TNCMS.Tracking.addData
	 */
	function _trackAam()
	{
		// This is an imperative call; first call? Eval whether to emit beacon
		if (m_bTrack === null) {
			if (!!m_bUnrestricted) {
				// True iff has any service ID whether URL has a match
				m_bTrack = _hasServiceAny() || false;
			} else {
				// True iff restricted and access by own service
				m_bTrack = _accessByOwnService() || false;
			}

			if (m_bTrack && TNCMS && TNCMS.Tracking &&
				TNCMS.Tracking.addData && TNCMS.Tracking.addEvent
			) {
				// Add data first instead of racing
				m_oMeta = document.querySelectorAll(
					'meta[name^="x-tncms-aam-"]'
				);
				if (m_oMeta) {
					_forEach(m_oMeta, function (i, oEl) {
						if (oEl && oEl.content && oEl.name &&
							oEl.name.length && oEl.name.length >= 12
						) {
							TNCMS.Tracking.addData({
								'name': oEl.name.substring(
									12, oEl.name.length
								), 'value': oEl.content
							});
						}
					});
				}
				TNCMS.Tracking.addEvent({
					'app': 'subscription', 'metric': 'view',
					'id': _matchingServiceIds()
				});
			}
		}
		return m_bTrack;
	}

	// }}}
	// {{{ void _onReadyDone(void)

	/**
	 * Call any callbacks enqueued with _onReady when done with async calls.
	 */
	function _onReadyDone() {
		var aCallback;
		if (m_aFnsOnReady !== null) {
			// Priming read on transition from unready to ready
			_isRestricted();
			_hasServiceAny();
			_accessByService();
			_hasServiceOwn();
			_hasServiceAdmin();
			_accessByAny();
			_accessByOwnService();
			_trackAam();
			while (m_aFnsOnReady.length > 0) {
				aCallback = m_aFnsOnReady.shift();
				aCallback[0].call(aCallback[1] || null, m_oExport);
			}
			m_aFnsOnReady = null;
		}
	}

	// }}}
	// {{{ void _onReady(function oCb, object oScope)

	/**
	 * Do something when requirements met, or now, if met already.
	 *
	 * @param function oCb    What to do.
	 * @param object   oScope Value of 'this' within the function.
	 */
	function _onReady(oCb, oScope) {
		if (m_aFnsOnReady === null) {
			oCb.call(oScope || null, m_oExport);
		} else {
			m_aFnsOnReady.push([oCb, oScope]);
		}
	}

	// }}}
	// {{{ void _initialize(object oCfg)

	/**
	 * Configure with the passed data and initialize asynchronous ready state.
	 *
	 * @param object oCfg Keyed configuration data.
	 */
	function _initialize(oCfg) {
		var sPathPre;
		oCfg = oCfg || {};

		if (!oCfg.service_id) {
			if (console.warn) {
				console.warn('Missing required config: service_id');
			}
			return;
		}

		m_aIdsUrl = _sanitizeStrArr(oCfg.service_id);
		if (!_isArray(m_aIdsUrl)) {
			if (console.warn) {
				console.warn('Invalid config: service_id');
			}
			return;
		}

		sPathPre = oCfg.prefix || '';
		m_bUnrestricted = !!oCfg.unrestricted;

		// Asynchronous Ready State Initialization
		try {
			m_oReq = new XMLHttpRequest();
		} catch (oExcIgnore) { /* ignore */ }
		if (!m_oReq) {
			_onReadyDone();
			return;
		}

		m_oReq.addEventListener('timeout', _onReadyDone);
		m_oReq.addEventListener('error', _onReadyDone);
		m_oReq.addEventListener('load', function () {
			var sType;
			var oResp;
			if (m_oReq.status == 200 || m_oReq.status == 304) {
				sType = m_oReq ? m_oReq.getResponseHeader(
					'Content-Type'
				) : null;
				if (sType && 'application/json' === sType.replace(
					/;.*/, ''
				).toLowerCase()) {
					try {
						oResp = JSON.parse(m_oReq.responseText);
					} catch (oExc) {
						oResp = undefined;
					}
				}
				m_bAccessByIp = !!oResp.whitelist;
				m_sErrorOnAccessByIp = oResp.error || null;
			}
			_onReadyDone();
		});
		m_oReq.open('GET', sPathPre +'/tncms/subscription/check_ip/', true);
		m_oReq.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
		m_oReq.timeout = 1000; // Limit local requests to be subsecond
		try {
			m_oReq.send(null);
		} catch (oExc) {
			_onReadyDone();
		}
	
		// If tab becomes active, then check to see if the user is still suppose
		// to have access. Test will only set cookies for the next request and
		// will not cause the paywall to change on the current page.
		
		if (document && window.fetch && localStorage) {
			
			if (!localStorage.getItem('tncms:subscription:cp')) {
				localStorage.setItem('tncms:subscription:cp', new Date());
			}
			
			document.addEventListener('visibilitychange', function(){
				if (!TNCMS.User.isLoggedIn()) {
					return;
				}
				
				var oNow = new Date();
				
				try {
					
					if (document.cookie.indexOf('tncms-services') != -1) {
					
						var oCP = new Date(localStorage.getItem('tncms:subscription:cp'));
						if ((oNow - oCP) < 14400000) {
							debugMsg('Subscription verification check skipped: check under 4 hours');
							return;
						}
						
					} else {
						
						debugMsg('Subscription service cookie missing - checking for update');
						
					}
					
				} catch (oError) {
					
					debugMsg('Subscription verification check failed: ' + oError.message);
					
				}
				
				debugMsg('Subscription verification check initiated');
				
				localStorage.setItem('tncms:subscription:cp', oNow);
				fetch(sPathPre + '/tncms/subscription/activate/?interactive=no', {
					method: 'POST'
				});
				
			});
		}

	}

	// }}}

	// }}}
	// {{{ Public Methods

	return {

		// {{{ void _initialize(object oCfg)

		/**
		 * Configure and initialize asynchronous ready state.
		 *
		 * @param object oCfg Keyed configuration data.
		 */
		initialize: _initialize,

		// }}}
		// {{{ void onReady(Function oCb, Object oScope)

		/**
		 * Do something when requirements met, or now, if met already.
		 *
		 * @param function oCb    What to do.
		 * @param object   oScope Value of 'this' within the function.
		 */
		onReady: _onReady

		// }}}

	}

	// }}}

})();
